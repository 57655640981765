import React from 'react';

const MarginQty = ({ active, marginQty }) => {
  return (
    <div className="card card-stretch card-bordered rounded-0">
      <div className="card-header px-4 pt-1 border-bottom-0">
        <div className="card-title fs-9 text-gray-400">
          <div className="symbol symbol-30px me-3">
            <div className={`symbol-label fs-6 fw-bolder ${active ? "bg-gray" : ""} text-white`}>M<small className="text-white">×</small>Q</div>
          </div>
          <div className="flex-column">
            <div className="text-gray-500 fs-9">Margin × Qty</div>
            <div className={`${active ? "text-gray-600" : "text-gray-500"} fs-8 lh-sm`}>粗利</div>
          </div>
        </div>
      </div>
      <div className="card-body px-4 pb-4 pt-0">
        <div className={`fs-3 fw-bolder ${marginQty == null ? "text-gray-500" : ""}`}>¥{marginQty == null ? '--' : marginQty.toLocaleString()}</div>
      </div>
    </div>
  );
};

export default MarginQty;
