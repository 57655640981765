import React, { useEffect, useState } from 'react';
import axios from '../../../lib/axios';
import Loading from '../spinners/loading';
import List from './list';
import moment from 'moment';

const All = ({ labels, size, defaultFrom, defaultTo, months, isProduction }) => {
  const [shopSummaries, setShopSummaries] = useState([]);
  const [labelId, setLabelId] = useState('');
  const [currentTerm, setCurrentTerm] = useState({from: defaultFrom, to: defaultTo});
  const [loading, setLoading] = useState(false);

  const getShopSummaries = (from, to, label_id, size) => {
    setLoading(true);
    axios.get(`shop_summaries/list`, {params: { size: size, label_id: labelId, from: from, to: to }})
      .then((response) => {
        $("#datatable_fixed_header1").DataTable().destroy(); // DataTable 削除
        setShopSummaries(response.data);
        setLoading(false);
      });
  };

  const numbering = () => {
    $('#datatable_fixed_header1 tbody tr td:first-child').each((index, td) => {
      td.innerText = index + 1;
    });
  };

  useEffect(() => {
    if (shopSummaries.length === 0 || $("#datatable_fixed_header1").hasClass('dataTable')) {
      return;
    }

    const datatable = new DataTable("#datatable_fixed_header1", {
      paging: false,
      info : false,
      scrollX:        true,
      scrollCollapse: true,
      order: [[ 2, "desc" ]],
      columns: [
        null,
        null,
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
      ],
      columnDefs: [{
        targets: [0, 1],
        orderable: false
      }],
      fixedHeader:{
        header: true,
        headerOffset: document.querySelector('.header').offsetHeight
      },
      fixedColumns: {
        leftColumns: 2,
      },
    });
    datatable.on('order', () => {
      numbering();
      $('#datatable_fixed_header1 thead').css('visibility', 'collapse');
    });
  }, [shopSummaries, loading]);

  useEffect(() => {
    getShopSummaries(currentTerm.from, currentTerm.to, labelId, size);
  }, [labelId, currentTerm]);

  useEffect(() => {
    // ラベル選択用
    $('#shop-summary-shop-label').select2();
    $('#shop-summary-shop-label').on('select2:select', (e) => {
      const value = e.currentTarget.value;
      if (!isNaN(parseInt(value))) {
        setLabelId(value);
      } else {
        setLabelId('');
      }
    });

    // 月選択用
    $('#shop-summary-month').select2({
      minimumResultsForSearch: Infinity
    });
    $('#shop-summary-month').on('select2:select', (e) => {
      const month = moment(e.currentTarget.value);
      setCurrentTerm({
        from: month.startOf('month').format('YYYY-MM-DD'),
        to: month.endOf('month').format('YYYY-MM-DD')
      });
    });
  }, []);

  return (
    <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12">
      <div className="card">
        <div className="card-header border-0 pt-8 pb-4 gap-6">
          <div className="d-flex flex-stack">
            <div className="symbol symbol-30px me-4">
              <span className="symbol-label bg-light-primary">
                <span className="svg-icon svg-icon-2 svg-icon-primary">
                  <span className="section-icon">
                    <i className="ki-duotone ki-data">
                      <i className="path1"></i>
                      <i className="path2"></i>
                      <i className="path3"></i>
                      <i className="path4"></i>
                      <i className="path5"></i>
                    </i>
                  </span>
                </span>
              </span>
            </div>
            <div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
              <div className="me-5">
                <span className="text-gray-800 fw-bolder fs-5">店舗別サマリー</span>
              </div>
            </div>
          </div>
          <div className="card-title my-0 mobile-flex-column gap-4 mobile-w-100 me-0">
          <div className="w-auto d-flex align-items-center mobile-w-100">
            <div className="text-gray-600 fs-8 me-3 nowrap">店舗ラベル</div>
              <div className="mobile-w-100">
                <select
                  className="form-select form-select-sm w-200px mobile-w-100"
                  id="shop-summary-shop-label"
                  data-control="select2"
                  data-placeholder="ラベルを選択"
                  disabled={loading}
                >
                  <option>すべて</option>
                  {labels.map((label) => {
                    return <option key={`label-${label.id}`} value={label.id}>{label.name}</option>
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 mobile-w-100">
            <select
              className="form-select py-2 w-md-175px"
              id="shop-summary-month"
              data-control="select2"
              data-hide-search="true"
              defaultValue={defaultFrom}
              disabled={loading}
            >
              {months.map((month) => {
                return (
                  <option value={month[1]} key={`month-option-${month[1]}`}>{month[0]}</option>
                )
              })}
            </select>
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <List shopSummaries={shopSummaries} />
        )}
      </div>
    </div>
  );
};

export default All;
