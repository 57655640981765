import React, { useState } from 'react';

const Price = ({ active, price }) => {
  return (
    <div className="card card-stretch card-bordered rounded-0">
      <div className="card-header px-4 pt-1 border-bottom-0">
        <div className="card-title fs-9 text-gray-500">
          <div className="symbol symbol-30px me-3">
            <div className={`symbol-label fs-6 fw-bolder ${active ? "bg-gray" : ""} text-white`}>P</div>
          </div>
          <div className="flex-column">
            <div className="text-gray-500 fs-9">Price</div>
            <div className={`${active ? "text-gray-600" : "text-gray-500"} fs-8`}>客単価</div>
          </div>
        </div>
      </div>
      <div className="card-body px-4 pb-4 pt-0">
        <div className={`fs-3 fw-bolder ${active ? "" : "text-gray-500"}`}>¥{price == null ? '--' : price.toLocaleString()}</div>
      </div>
    </div>
  );
};

export default Price;
