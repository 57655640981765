import React, { useCallback } from 'react';

const Margin = ({ active, marginRate, price, style }) => {
  const margin = useCallback(() => {
    if (price == null) {
      return null;
    }

    return Math.round(price * marginRate / 100);
  }, [price, marginRate]);

  return (
    <div className="card card-bordered rounded-0" style={style}>
      <div className="card-header px-4 pt-1 border-bottom-0">
        <div className="card-title fs-9 text-gray-400">
          <div className="symbol symbol-30px me-3">
            <div className={`symbol-label fs-6 fw-bolder ${active ? "bg-gray" : ""} text-white`}>M</div>
          </div>
          <div className="flex-column">
            <div className="text-gray-500 fs-9">Margin</div>
            <div className={`${active ? "text-gray-600" : "text-gray-500"} fs-8`}>粗利単価</div>
          </div>
        </div>
      </div>
      <div className="card-body px-4 pb-4 pt-0">
        <div className={`fs-3 fw-bolder ${margin() == null ? "text-gray-500" : ""} mb-3`}>
          ¥{margin() == null ? '--' : margin().toLocaleString()}
        </div>
        <div className={`fs-9 fw-bold ${margin() == null ? "text-gray-500" : "text-gray-600"} mb-0`}>粗利率</div>
        <div className={`fs-4 fw-bolder ${margin() == null ? "text-gray-500" : ""} mb-3`}>{marginRate == null ? '--' : marginRate}%</div>
      </div>
    </div>
  );
};

export default Margin;
