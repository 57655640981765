import React, { useEffect, useState, useMemo } from 'react';
import Calendar from 'react-calendar';
import Modal from './modal';
import ModalBias from './modal_bias';
import axios from '../../../lib/axios';
import moment from "moment";

const DailyCalendar = ({ year, month, budgetGoal, defaultDailyBudgets, defaultBaseWeekday, defaultBias, holidays, businessDays, regularHolidays, prevUrl, nextUrl}) => {
  const [dailyBudget, setDailyBudget] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [baseWeekday, setBaseWeekday] = useState(defaultBaseWeekday); // 月曜日
  const [bias, setBias] = useState(defaultBias); // バイアス
  const [dailyBudgets, setDailyBudgets] = useState(defaultDailyBudgets);
  const [initialRender, setInitialRender] = useState(true); // バイアス保存するための初期状態判定

  const currentMonth = useMemo(() => moment({year: year, month: month - 1, date: 1 }));

  useEffect(() => {
    // 分母を作成
    let sumBias = 0;
    businessDays.forEach((businessDay) => {
      const weekdayBias = getWeekdayBias(businessDay);
      sumBias += weekdayBias;
    });

    // 日次予算を再計算
    // 画面にバイアス、日次を反映する(まだDB保存しない)
    let newDailyBudgets = {};
    businessDays.forEach((businessDay) => {
      const weekdayBias = getWeekdayBias(businessDay);

      // バイアスをかけた値を計算
      const sales = budgetGoal.monthly_sales * weekdayBias / sumBias;
      const customers = sales / budgetGoal.sales_per_customer;
      const dailyFoodCost = Math.round(sales * budgetGoal.food_cost_rate / 100);
      const dailyLaborCost = Math.round(sales * budgetGoal.labor_cost_rate / 100);

      newDailyBudgets[businessDay] = {
        customers: Math.round(customers),
        sales: Math.round(sales),
        food_cost: dailyFoodCost,
        labor_cost: dailyLaborCost
      }
    });

    setDailyBudgets(newDailyBudgets);
  }, [bias]);

  useEffect(() => {
    // 初期化時は保存したくないので、dailyBudgets に変更があったら保存する
    if (! initialRender) {
      updateDailyBudgetHandler();
    }
    // 依存配列に initialRender と updateDailyBUdgetHandler はあえて含めていません
  }, [dailyBudgets]);

  // カレンダーの日付をclickした時
  const clickDateHandler = (date) => {
    setCurrentDate(date);
    const budget = dailyBudgets[date];
    if (budget) {
      budget.food_cost = Math.round(budget.sales * budgetGoal.food_cost_rate / 100);
      budget.labor_cost = Math.round(budget.sales * budgetGoal.labor_cost_rate / 100);
    }

    setDailyBudget(budget);
  };

  // バイアスの入力値から実際にかける数値を計算する
  const getWeekdayBias = (key) => {
    let value = null;
    // 祝日を優先
    if (holidays.includes(key)) {
      value = bias.holiday;
    } else {
      const date = moment(key);
      const weekday = date.format('dddd').toLowerCase();
      value = bias[weekday];
    }

    return value;
  };

  // 保持しているバイアス情報を更新
  const applyBiasHandler = (draftBaseWeekday, draftBias) => {
    setBaseWeekday(draftBaseWeekday);
    setBias({...draftBias});
    $('#modal_budget_bias').modal('hide');
    setInitialRender(false); // 日次が変更になったので、保存OK
  };

  // サーバーへ送信
  const updateDailyBudgetHandler = () => {
    if (!baseWeekday) {
      alert('基準曜日を指定してください');
      return;
    }

    // 日次予算、バイアス設定UPDATE DB
    const url = '/budget_controls/calendar/bias';
    let daily = [];
    Object.keys(dailyBudgets).forEach((key) => {
        daily.push({
          date: key,
          ...dailyBudgets[key],
        });
    });

    axios.put(url, {
      year: year, 
      month: month,
      monthly_budget_bias_settings: {
        base_weekday: baseWeekday,
        ...bias
      },
      daily_budget: daily
    })
    .then((response) => {
      // Nothing to do
    })
    .catch((_error) => {
      alert('保存に失敗しました。バイアスの内容をご確認ください');
    });
  };

  // 前月のバイアス設定をコピー
  const copyLastMonthBiasHander = () => {
    const lastMonth = currentMonth.clone().subtract(1, 'months');
    const url = `/budget_controls/calendar/bias/${lastMonth.year()}/${lastMonth.month() + 1}`;
    axios.get(url)
    .then((response) => {
      if (response.data.bias) {
        const draftBias = {
          sunday: response.data.bias.sunday,
          monday: response.data.bias.monday,
          tuesday: response.data.bias.tuesday,
          wednesday: response.data.bias.wednesday,
          thursday: response.data.bias.thursday,
          friday: response.data.bias.friday,
          saturday: response.data.bias.saturday,
          holiday: response.data.bias.holiday
        };
        applyBiasHandler(response.data.bias.base_weekday, draftBias);
      } else {
        alert('前月のバイアス設定が存在しないため、コピーできませんでした。');
      }
    });
  };

  const getTileContent = ({ date, view }) => {
    if (view !== "month") {
      return null;
    }

    const day = moment(date);
    // 違う月の場合は tile を表示しない
    if (currentMonth.month() != day.month()) {
        return null;
    }

    const key = day.format("YYYY-MM-DD");
    const budget = dailyBudgets[key] ? dailyBudgets[key] : {
      customers: 0,
      sales: 0
    };

    // 予算
    const customers = parseInt(budget.customers);
    const sales = "¥" + Number(budget.sales.toFixed(0)).toLocaleString();

    // font color
    const colorBudget = "text-gray-700";

    return (
      <div
        className="fc-daygrid-day-frame fc-scrollgrid-sync-inner"
        data-bs-toggle="modal"
        data-bs-target="#kt_modal_daily_detail"
        onClick={() => {clickDateHandler(key)}}
      >
        <div className="bg-hover-light overlay pb-3 px-3 d-flex-mobile">
          <div className="flex-stack-mobile">
            <div className="d-flex flex-stack mb-0 fs-8 text-gray-500">
              <div className="align-items-center">客数</div>
              <div className="align-items-center">売上</div>
            </div>
            <div className="d-flex flex-stack">
              <div className={colorBudget}>{customers}</div>
              <div className={colorBudget}>{sales}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const formatDay = (locale, date) => {
    const day = moment(date);
    const dayOfWeeks = ["日", "月", "火", "水", "木", "金", "土"];
    const dayOfWeek = dayOfWeeks[day.weekday()];
    return (
      <>
        {day.format('D')}
        <small>日</small>
        <span className="day-mobile"><small>({dayOfWeek})</small></span>
      </>
    );
  };

  const getTileClassName = ({ date, view }) => {
    if (view !== "month") {
      return null;
    }

    const day = moment(date);
    if (holidays.includes(day.format('YYYY-MM-DD'))) {
      return "react-calendar__month-view__days__day--weekend";
    }

    return "";
  };

  return (
    <>
      <div className="fc-header-toolbar fc-toolbar-ltr d-flex align-items-center mb-5 mobile-flex-column">
        <div className="d-flex align-items-center w-100 me-md-2">
          <div className="fc-toolbar-chunk">
            <h2 className="fc-toolbar-title fs-3">{year}年{month}月</h2>
          </div>
        </div>
        <div className="ms-md-2 w-100 w-md-auto d-flex">
          <div className="ps-1 w-50 w-md-auto">
            <a className="btn fw-bold btn-primary w-100 nowrap" onClick={copyLastMonthBiasHander}>
              前月のバイアス設定をコピー
            </a>
          </div>
          <div className="ps-1 w-50 w-md-auto">
            <a className="btn fw-bold btn-gray w-100 nowrap" onClick={() => {$('#modal_budget_bias').modal('show')}}>
              <i className="ki-outline ki-setting-3 fs-4 ms-n1 me-1 mb-1"></i>
              バイアス設定
            </a>
          </div>
        </div>
      </div>
      <div className="fc-view-harness fc-view-harness-active h-auto">
        <div className="fc-daygrid fc-dayGridMonth-view fc-view">
          <div className="calendar">
            <Calendar
              calendarType="US"
              className="is-fullwidth"
              locale="ja-JP"
              minDetail="month"
              defaultActiveStartDate={new Date(currentMonth.year(), currentMonth.month(), 1)}
              tileClassName={getTileClassName}
              tileContent={getTileContent.bind(this)}
              minDate={currentMonth.startOf('month').toDate()}
              maxDate={currentMonth.endOf('month').toDate()}
              formatDay={formatDay}
              prevLabel={null}
              prev2Label={null}
              nextLabel={null}
              next2Label={null}
              showNavigation={false}
            >
            </Calendar>
          </div>
        </div>
      </div>
      <Modal date={currentDate} dailyBudget={dailyBudget} />
      <ModalBias
        regularHolidays={regularHolidays}
        baseWeekday={baseWeekday}
        bias={bias}
        applyBiasHandler={applyBiasHandler}
      />
    </>
  );
};

export default DailyCalendar;
