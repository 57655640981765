import React, { useEffect, useState } from 'react';

const ModalBias = ({ regularHolidays, baseWeekday, bias, applyBiasHandler }) => {
  const [step, setStep] = useState(1);
  const [draftBaseWeekday, setDraftBaseWeekday] = useState(baseWeekday);
  const [draftBias, setDraftBias] = useState(bias);

  useEffect(() => {
    setDraftBias(bias)
    setDraftBaseWeekday(baseWeekday)
  }, [bias]);

  const weekdays = [
      {ja: '月', ja_long: '月曜日', key: 'monday', value: 1},
      {ja: '火', ja_long: '火曜日', key: 'tuesday', value: 2},
      {ja: '水', ja_long: '水曜日', key: 'wednesday', value: 3},
      {ja: '木', ja_long: '木曜日', key: 'thursday', value: 4},
      {ja: '金', ja_long: '金曜日', key: 'friday', value: 5},
      {ja: '土', ja_long: '土曜日', key: 'saturday', value: 6},
      {ja: '日', ja_long: '日曜日', key: 'sunday', value: 0}
    ];

  const clickBaseWeekdayHandler = (e) => {
    setDraftBaseWeekday(e.currentTarget.value);
  };

  const changeBiasHandler = (weekday, e) => {
    const value = e.currentTarget.value;
    draftBias[weekday] = value == "" ? "" : +value;
    setDraftBias({...draftBias});
  };

  const backStepHandler = () => {
    setStep(1);
  };

  const nextStepHandler = () => {
    if (draftBaseWeekday == null) {
      alert("基準曜日を選択してください");
      return;
    }
    // 基準曜日のBiasを100にする
    draftBias[draftBaseWeekday] = 100;
    setDraftBias({...draftBias});
    setStep(2);
  };

  const clickSaveHandler = () => {
    const values = Object.values(draftBias);
    if (values.find((value) => value == "") == "") {
      alert("バイアスを全て入力してください");
    } else {
      applyBiasHandler(draftBaseWeekday, draftBias);
    }
  };

  const renderStep1 = () => {
    const elements = weekdays.map((w) => {
      return (
        <div className="fv-row mb-4" key={`base_weekday-${w.key}`}>
          <div className="form-check form-check-custom form-check-solid form-check-lg">
            <input
              className="form-check-input"
              type="radio"
              defaultValue={w.key}
              id={`base_weekday-${w.key}`}
              name="day"
              checked={draftBaseWeekday == w.key}
              disabled={regularHolidays.includes(w.value)}
              onChange={clickBaseWeekdayHandler}
            />
            <label className="form-check-label fw-bold" htmlFor={`base_weekday-${w.key}`}>
              {w.ja_long}
            </label>
          </div>
        </div>
      );
    });

    return (
      <div className={`flex-column ${step == 1 ? 'current' : 'completed'}`} data-kt-stepper-element="content">
        <div className="mb-6 d-flex align-items-center">
          <div className="flex-column">
            <div className="fs-6 fw-bold text-gray-600">基準曜日を選択してください</div>
          </div>
        </div>
        {elements}
      </div>
    );
  };

  const renderStep2 = () => {
    const elements = weekdays.map((w) => {
      return (
        <tr key={`bias-${w.key}`}>
          <td className="nowrap fw-bold fs-7">{w.ja}</td>
          <td className="text-end">
            <div className="input-group input-group-solid">
              <input
                type="number"
                className="form-control"
                value={draftBias[w.key]}
                disabled={draftBaseWeekday == w.key}
                onChange={(e) => changeBiasHandler(w.key, e)}
              />
              <span className="input-group-text">%</span>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <div className={`flex-column ${step == 2 ? 'current' : 'pending'}`} data-kt-stepper-element="content">
        <div className="d-flex flex-wrap d-grid mb-0 row">
          <div className="table-responsive">
            <table className="table align-middle gy-2 gs-0">
              <thead>
                <tr className="fw-bolder fs-7 text-muted">
                  <th className="min-w-50px">曜日</th>
                  <th className="min-w-100px">バイアス</th>
                </tr>
              </thead>
              <tbody>
                {elements}
                <tr>
                  <td className="nowrap fw-bold fs-7">祝
                    <span className="ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="曜日設定の値を上書きします">
                      <i className="ki-duotone ki-information-5 text-gray-600 fs-5">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                    </span>
                  </td>
                  <td className="text-end">
                    <div className="input-group input-group-solid">
                      <input
                        type="number"
                        className="form-control"
                        value={draftBias.holiday}
                        onChange={(e) => changeBiasHandler("holiday", e)}
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
         </div>
        </div>
      </div>
    );
  };

  return (
    <div className="modal fade" id="modal_budget_bias" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mw-lg-400px">
        <div className="modal-content rounded pb-10">
          <div className="modal-header mb-5">
            <h3 className="modal-title mt-2">バイアス設定</h3>
            <div className="btn btn-icon btn-sm ms-2" data-bs-dismiss="modal" aria-label="Close">
              <i className="ki-outline ki-cross fs-1 text-gray-500"></i>
            </div>
          </div>
          <div className={`stepper stepper-pills ${step == 2 ? 'last' : 'first'}`} id="modal_budget_bias_clickable">
            <div className="stepper-nav flex-center flex-wrap mb-4">
              <div
                className={`stepper-item mx-8 my-4 ${step == 1 ? 'current' : 'completed'}`}
                data-kt-stepper-element="nav" data-kt-stepper-action="step"
              >
                <div className="stepper-wrapper d-flex align-items-center" onClick={backStepHandler}>
                  <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check ki-duotone ki-check fs-2"></i>
                    <span className="stepper-number">1</span>
                  </div>
                  <div className="stepper-label pt-1">
                    <h3 className="stepper-title my-0">ステップ 1</h3>
                    <div className="stepper-desc fw-bold">基準曜日設定</div>
                  </div>
                </div>
                <div className="stepper-line h-40px"></div>
              </div>
              <div
                className={`stepper-item mx-8 my-4 ${step == 2 ? 'current' : 'pending'}`}
                data-kt-stepper-element="nav" data-kt-stepper-action="step"
              >
                <div className="stepper-wrapper d-flex align-items-center" onClick={nextStepHandler}>
                  <div className="stepper-icon w-40px h-40px">
                    <span className="stepper-number">2</span>
                  </div>
                  <div className="stepper-label pt-1">
                    <h3 className="stepper-title my-0">ステップ 2</h3>
                    <div className="stepper-desc fw-bold">バイアス設定</div>
                  </div>
                </div>
              </div>
            </div>
            <form className="form w-lg-350px mx-auto px-md-0 px-8" noValidate="novalidate">
              <div className="mb-5">
                {renderStep1()}
                {renderStep2()}
              </div>
              <div className="d-flex flex-stack">
                <div className="me-2">
                  <button
                    type="button"
                    className="btn btn-light btn-active-light-primary"
                    data-kt-stepper-action="previous"
                    onClick={backStepHandler}
                  >
                    戻る
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-kt-stepper-action="submit"
                    onClick={clickSaveHandler}
                  >
                    <span className="indicator-label">適用する</span>
                    <span className="indicator-progress">
                      保存･反映中... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary"
                    data-kt-stepper-action="next"
                    onClick={nextStepHandler}
                  >
                    次へ
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalBias;
