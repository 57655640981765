import React from 'react';
import Chart from 'react-apexcharts';

const Abc = ({ sales, sales_ratio, categories }) => {
  const xGridWidth = 60;
  const classAIndex = sales_ratio.findIndex((ratio) => ratio >= 70);
  const classBIndex = sales_ratio.findIndex((ratio) => ratio >= 90);
  const classCIndex = sales_ratio.length - 1;

  const series = [
    {
      name: '売上（税込）',
      type: "bar",
      data: sales
    },
    {
      name: '売上構成比率',
      type: 'line',
      data: sales_ratio
    }
  ];
  const options = {
    chart: {
      type: 'line',
      height: 300,
      fontFamily: 'inherit',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: [6],
        columnWidth: '40%',
        endingShape: 'rounded'
      },
    },
    legend: {
      show: false
    },
    dataLabels: {
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        borderRadius: 2,
        padding: 4,
        opacity: 1,
        borderWidth: 1,
        borderColor: '#fff'
      },
      enabled: true,
      enabledOnSeries: [1]
    },
    stroke: {
      curve: 'straight',
      show: true,
      width: 2
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          fontFamily: 'inherit',
          colors: KTUtil.getCssVariableValue("--bs-gray-500"),
          fontSize: "12px"
        }
      }
    },
    yaxis: [
      {
        seriesName: '売上合計',
        show: false
      },
      {
        opposite: true,
        seriesName: '累計構成比',
        show: false
      }
    ],
    annotations: {
      xaxis: [
        {
          x: classAIndex * (xGridWidth + 0.2) - 0.8,
          borderColor: '#FF4560',
          strokeDashArray: 0,
          label: {
            borderColor: '#F687B3',
            position: 'top',
            orientation: 'horizontal',
            offsetY: -15,
            style: {
              color: '#fff',
              background: '#FF4560',
              fontSize: '10px',
            },
            text: 'Aランク：0〜70%',
          }
        },
        {
          x: classBIndex * (xGridWidth + 0.2) + 0.5,
          borderColor: '#008FFB',
          strokeDashArray: 0,
          label: {
            borderColor: '#B794F4',
            position: 'top',
            orientation: 'horizontal',
            offsetY: -15,
            style: {
              color: '#fff',
              background: '#008FFB',
              fontSize: '10px',
            },
            text: 'Bランク：71〜90%',
          }
        },
        {
          x: classCIndex * (xGridWidth + 0.3),
          borderColor: '#FEB019',
          strokeDashArray: 0,
          label: {
            borderColor: '#63B3ED',
            position: 'top',
            orientation: 'horizontal',
            offsetX: -50,
            offsetY: -15,
            style: {
              color: '#fff',
              background: '#FEB019',
              fontSize: '10px',
            },
            text: 'Cランク：91〜100%',
          }
        }
      ]
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: [{
        formatter: function(y) {
          if (typeof y !== "undefined") {
            return "¥" + y.toFixed(0);
          }
          return y;
        }
      }, {
        formatter: function(y) {
          if (typeof y !== "undefined") {
            return y.toFixed(1) + "%";
          }
          return y;
        }
      }]
    },
    colors: [KTUtil.getCssVariableValue("--bs-primary"), KTUtil.getCssVariableValue("--bs-gray-500")],
    grid: {
      borderColor: KTUtil.getCssVariableValue("--bs-gray-200"),
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    }
  };

  return (
    <div id="chart_analysis_sales_item_ratio" className="pe-md-4 pb-2" style={{ overflow: 'scroll' }}>
      <Chart options={options} series={series} type="line" height="300" width={categories.length * xGridWidth} />
    </div>
  );
};

export default Abc;
