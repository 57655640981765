import React, { useEffect, useState } from 'react';
import axios from '../../../lib/axios';
import List from './list';
import ModalLabels from './modal_labels';
import Loading from '../spinners/loading';
import moment from 'moment';

const All = ({ labels, checkedLabelIds, size, defaultFrom, defaultTo, months }) => {
  const [shopLabelSummaries, setShopLabelSummaries] = useState([]);
  const [labelIds, setLabelIds] = useState(checkedLabelIds ?? []);
  const [currentTerm, setCurrentTerm] = useState({from: defaultFrom, to: defaultTo});
  const [loading, setLoading] = useState(false);

  const getShopLabelSummaries = (from, to, label_id, size) => {
    setLoading(true);
    axios.get(`shop_label_summaries/list`, {params: { size: size, label_id: labelIds, from: from, to: to }})
      .then((response) => {
        setShopLabelSummaries(response.data);
        setLoading(false);
      });
  };

  const numbering = () => {
    $('#datatable_fixed_header2 tbody tr td:first-child').each((index, td) => {
      td.innerText = index + 1;
    });
  };

  useEffect(() => {
    if (shopLabelSummaries.length === 0 || $("#datatable_fixed_header2").hasClass('dataTable')) {
      return;
    }

    const datatable = new DataTable("#datatable_fixed_header2", {
      paging: false,
      info : false,
      scrollX:        true,
      scrollCollapse: true,
      order: [[ 2, "desc" ]],
      columns: [
        null,
        null,
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
      ],
      columnDefs: [{
        targets: [0, 1],
        orderable: false
      }],
      fixedHeader:{
        header: true,
        headerOffset: document.querySelector('.header').offsetHeight
      },
      fixedColumns: {
        leftColumns: 2,
      }
    });
    datatable.on('order', () => {
      numbering();
      $('#datatable_fixed_header2 thead').css('visibility', 'collapse');
    });
  }, [shopLabelSummaries, loading]);

  useEffect(() => {
    getShopLabelSummaries(currentTerm.from, currentTerm.to, labelIds, size);
  }, [labelIds, currentTerm]);

  useEffect(() => {
    // 月選択用
    $('#shop-summary-month').select2({
      minimumResultsForSearch: Infinity
    });
    $('#shop-summary-month').on('select2:select', (e) => {
      const month = moment(e.currentTarget.value);
      setCurrentTerm({
        from: month.startOf('month').format('YYYY-MM-DD'),
        to: month.endOf('month').format('YYYY-MM-DD')
      });
    });
  }, []);

  const changeLabelsHandler = (newLabelIds) => {
    setLabelIds(newLabelIds);
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12">
        <div className="card">
          <div className="card-header border-0 pt-8 pb-2 gap-6">
            <div className="d-flex flex-stack">
              <div className="symbol symbol-30px me-4">
                <span className="symbol-label bg-light-primary">
                  <span className="svg-icon svg-icon-2 svg-icon-primary">
                    <span className="section-icon">
                      <i className="ki-duotone ki-abstract-12">
                        <i className="path1"></i>
                        <i className="path2"></i>
                      </i>
                    </span>
                  </span>
                </span>
              </div>
              <div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
                <div className="me-5">
                  <span className="text-gray-800 fw-bolder fs-5">店舗ラベル別サマリー</span>
                </div>
              </div>
            </div>
            <div className="card-title my-0 mobile-flex-column gap-4 mobile-w-100 me-0">
              <div className="w-auto d-flex align-items-center mobile-w-100">
                <div className="mobile-w-100">
                  <div className="w-auto">
                    <button
                      type="button"
                      className="btn btn-sm fs-7 fw-bold btn-primary nowrap mobile-w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#modal_labels"
                    >
                      <i className="ki-outline ki-color-swatch fs-4 ms-n1 me-0 mb-1">
                      </i>
                      店舗ラベル選択
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mobile-w-100 mb-2">
              <select
                className="form-select py-2 w-md-175px"
                id="shop-summary-month"
                data-control="select2"
                data-hide-search="true"
                disabled={loading}
              >
                {months.map((month) => {
                  return (
                    <option value={month[1]} key={`month-option-${month[1]}`}>{month[0]}</option>
                  )
                })}
              </select>
            </div>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <List shopLabelSummaries={shopLabelSummaries} />
          )}
        </div>
      </div>
      <ModalLabels labels={labels} checkedLabelIds={labelIds} changeLabelsHandler={changeLabelsHandler} />
    </>
  );
};

export default All;
